.App {
  text-align: center;
  /* background: rgb(255, 211, 211); */
  background-image: url("assets/doggo.jpg");
  background-blend-mode: multiply;
  background-repeat: repeat-x repeat-y;
  animation: colorchange 1.5s 1s infinite cubic-bezier(1,.03,1,-0.15); 
}

.App-logo {
  height: 20vmin;
  pointer-events: none;

}
.logo{
  background-color: rgba(255, 255, 255, 0.473);
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.63);
  padding: 20px;
}
.name{
  font-size: 32pt;
  top:25px;
  left:25px;
  position: absolute;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  user-select: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin infinite .5s ease-out;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border: 20px solid white;
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    transform: scale(1.0);
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50%{
    transform: scale(1.1);
  }
  75%{
    transform: rotate(-1deg);
  }
  100%{
    transform: scale(1.0);
  }
}

@keyframes colorchange
{
  0%   {background-color: rgba(255, 211, 211, 1);}
  33%  {background-color: rgba(255, 177, 207, 1);}
  66%  {background-color: rgba(214, 214, 255,1);}
  100% {background-color: rgba(255, 177, 207,1);}
}